<template>
	<div class="create-pin">
		<div class="header">
			<img src="@/assets/images/icons/ic_back_nav.svg" alt="back" class="cursor-pointer ml-auto" @click="$router.go(-1)">
		</div>
		<div class="create-pin__container">
			<div class="create-pin__card card">
				<div class="card-body">
            		<img src="@/assets/images/soca-logo-full.svg" alt="SOCA" class="img-fluid soca-logo mt-3">
					<h3 class="cpc__title my-4">Create PIN</h3>
					<h5 class="text-left ml-md-3 font-weight-normal mb-1">New PIN</h5>
					<div style="display: flex; flex-direction: row;" class="mb-4 mx-md-3 verivikasi-pin">
						<v-otp-input
							ref="pinInput"
							input-classes="otp-input form-control text-center"
							input-type="password"
							separator="&nbsp;&nbsp;&nbsp;"
							:num-inputs="6"
							:should-auto-focus="true"
							:is-input-num="true"
							@on-change="handleOnChangePin"
							@on-complete="handleOnCompletePin"
						/>
					</div>
					<h5 class="text-left ml-md-3 font-weight-normal mb-1 mt-3">Confirm PIN</h5>
					<div style="display: flex; flex-direction: row;" class="mx-md-3 verivikasi-pin" :class="!validateInput ? 'mb-4' : ''">
						<v-otp-input
							ref="pinConfirmInput"
							:input-classes="validateInput ? 'otp-input form-control text-center not-match' : 'otp-input form-control text-center'"
							input-type="password"
							separator="&nbsp;&nbsp;&nbsp;"
							:num-inputs="6"
							:should-auto-focus="true"
							:is-input-num="true"
							@on-change="handleOnChangeConfirmPin"
							@on-complete="handleOnCompleteConfirmPin"
						/>
					</div>
					<p v-if="validateInput" class="mb-0 ml-3 text-left mb-3 mt-1" style="color:#DF2723;">The code you entered is incorrect.</p>
					<div class="mx-md-3 mb-3">
						<button class="btn-save btn" @click="!loading ? save() : ''" type="button">
							<div v-if="loading" class="spinner-border text-light" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
							<span v-else>Submit</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { setPin } from '@/services/pin/pin.service'
import { AlertUtils } from "@/mixins/AlertUtils";

export default {
	mixins : [AlertUtils],
	data() {
		return {
			pin: null,
			confirmPin: null,
			isSame: null,
			loading: false,
		}
	},

	computed : {
		validateInput() {
			return this.isSame != null && this.isSame == false
		}
	},

	methods: {
		handleOnChangePin(val){
			this.pin = val
		},
		handleOnCompletePin(val) {
			this.pin = val
		},
		handleOnChangeConfirmPin(val){
			this.isSame = null
			this.confirmPin = val
		},
		handleOnCompleteConfirmPin(val) {
			this.confirmPin = val
		},
		save() {
			if(this.confirmPin === this.pin) {
				this.loading = true
				this.isSame = true
				let formData = {
					newPIN: this.pin,
					confirmPIN : this.confirmPin
				}

				setPin(formData)
				.then((response) => {
					if(response.status) {
						this.loading = false
						this.alertSuccess(this.$t('otp-sent-email'))
						if(this.$route.query.request == 'payment') {
							this.$router.push({ name : 'Verifikasi', query : { key: 'set-pin', request : 'payment', package : this.$route.query.package } })
						} else if(this.$route.query.request == 'change-pin') {
							this.$router.push({ name : 'Verifikasi', query : { key: 'set-pin', request : 'change-pin' } })
						} else {
							this.$router.push({ name : 'Verifikasi', query : { key: 'set-pin', request : 'forgot-pin', package : this.$route.query.package } })
						}
					}
				})
				.catch((error) => {
					this.loading = false
					console.log(error)
				})
			} else {
				this.isSame = false
			}
		},
	}
}
</script>

<style scoped>
.create-pin {
	height: 100vh
}

.create-pin .header {
	width: 100%;
    padding: 16px 48px;
    text-align: left;
    border-bottom: 0.1px solid #1C1C1C;
}

.create-pin__container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 90%;
}

.create-pin__card {
	width: 30%;
	color: white;
}

.cpc__title {
	font-size: 30px;
}

.btn-save {
	background-color: #6D75F6;;
	border-radius: 8px;
	padding: 12px;
	width: 100%;
	color: white;
	font-weight: 900;
	font-size: 16px;
}
</style>

<style scoped lang="less">
  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid #DF2723 !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>

<style>
.verivikasi-pin {
  display: flex;
  justify-content: center;
}

.verivikasi-pin .form-control{
	flex: 1;
    color: white;
    background-color:transparent;
    border: 1px solid #383838;
    max-width: 50px;
	height: 50px;
    padding: 0;
}

.verivikasi-pin input:focus{
    color: white;
    background-color:transparent;
}

.not-match {
	border: 1px solid #DF2723 !important;
}
</style>